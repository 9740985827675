import { render, staticRenderFns } from "./AppFooterContact.vue?vue&type=template&id=4928a812&scoped=true&"
import script from "./AppFooterContact.vue?vue&type=script&lang=ts&"
export * from "./AppFooterContact.vue?vue&type=script&lang=ts&"
import style0 from "./AppFooterContact.vue?vue&type=style&index=0&id=4928a812&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4928a812",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VIcon,VList,VListItem,VListItemContent,VListItemGroup,VListItemTitle})
